<template>
  <TosListVuetify serviceName="registry"  entityName="locodes" :headers="headers" :validateFunction="validate" >
    <template #item.actions="slotProps">
      <v-btn icon title="Modifica" @click="$emit('tableOpenItem', slotProps.data.codice)">
        <v-icon color="primary" medium>mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon title="Elimina" @click="onDelete(slotProps.data)">
        <v-icon color="primary" medium>mdi-delete</v-icon>
      </v-btn>
    </template>
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-text-field label="Codice" v-model="slotProps.data.codiceLike" />
          </v-col>
          <v-col cols="4">
            <v-text-field label="Descrizione" v-model="slotProps.data.descrizioneLike" />
          </v-col>
          <v-col cols="4">
            <v-text-field label="Nazione" v-model="slotProps.data.codNazione" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-slot:editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
            <v-col cols="3">
              <v-text-field :label="tLabel('codice') + '*'"  v-model="slotProps.data.codice" :rules="[rules.required]" :error-messages="slotProps.errors.codice" />
            </v-col>
            <v-col cols="3">
              <v-select label="Nazione '*'"  v-model="slotProps.data.codNazione" :items="countries" :rules="[rules.required]" :error-messages="slotProps.errors.codNazione" />
            </v-col>
            <v-col cols="6">
              <v-text-field :label="tLabel('descrizione') + '*'"  v-model="slotProps.data.descrizione" :rules="[rules.required]" :error-messages="slotProps.errors.descrizione" />
            </v-col>
        </v-row>
      </div>
    </template>

  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "Locode",
  data() {
    return {
      countries: [],
      headers: [
        { value: "codice",  text: "Codice"},
        { value: "descrizione", text: "Descrizione"},
        { value: "codNazione",  text: "Nazione"},
      ],
    };
  },
  service: null,
  created() {
  },
  async mounted(){
    const countriesList=await this.$api.get(this.$apiConfiguration.BASE_PATH_REGISTRY+"countries/list");
    const countries=[];
    countriesList.data.forEach(function(t) {countries.push({value: t.codice, text:t.descrizione})});
    this.countries=countries;
  },
  methods: {
  validate(item, errors) {
      if(item.codice.length!=5) errors.add("codice","Deve essere 5 caratteri");
      item.codice=item.codice.toUpperCase();
    },
    onDelete(item){
      item.id=item.codice;
      this.$emit('confirm', 'Confermi la cancellazione ? ', 'deleteItem', item.codice)
    }
  },
  components: {
    TosListVuetify,
  },
  mixins: [FormatsMixins],
};
</script>